import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { User, UserUpdatedData } from '@/types/users';
import { userApi } from '@/api/user';
import { isErrorMessageResponse, isUser } from '~/types/guards/api-responses';
import { logout as signOut } from '~/auth';
import { useCompanyStore } from '~/stores/company';
import { storageLocal } from '@pureadmin/utils';

/**
 * User store.
 */

export const useUserStore = defineStore('userStore', () => {
  const user = ref<User | null>(null);
  const avatar = ref<string | null>(null);

  const loading = ref<boolean>(false);
  const error = ref<null | string>(null);

  async function hydrate(): Promise<'ok' | 'error'> {
    this.loading = true;
    try {
      const res = await userApi.me();
      if (isErrorMessageResponse(res)) {
        if (res.errorCode === 'INVALID_CREDENTIALS_ERROR') {
          await signOut();
          this.dehydrate();
        }
        // error.value = res.errorCode;
        return 'error';
      }
      user.value = { ...res, assignedMinutes: res.remainingMinutes };
      if (!avatar.value) {
        await this.getAvatar();
      }
      return 'ok';
    } catch (error) {
      this.error = 'Ошибка аутентификации';
      storageLocal().removeItem('user-info');
      this.dehydrate();
      return 'error';
    } finally {
      this.loading = false;
    }
  }

  function $reset() {
    user.value = null;
    avatar.value = null;
  }

  function dehydrate() {
    this.$reset();
  }

  async function updateUser(id: number | string, data: UserUpdatedData): Promise<'ok' | 'error'> {
    try {
      const response = await userApi.update(id, data);
      if (isErrorMessageResponse(response)) {
        error.value = response.errorCode;
        return 'error';
      }
      if (isUser(response)) {
        user.value = { ...user.value, ...response, avatar: avatar.value };
        return 'ok';
      }
    } catch (error) {
      this.error = 'Ошибка обновления';
      return 'error';
    }
  }

  async function updateAvatar(avatar: File): Promise<'ok' | 'error'> {
    try {
      const res = await userApi.updateAvatar({ avatar });
      if (isErrorMessageResponse(res)) {
        error.value = res.errorCode;
        return 'error';
      }
      await this.getAvatar();
      return 'ok';
    } catch (error) {
      error.value = 'Ошибка обновления';
      return 'error';
    }
  }

  async function getAvatar(): Promise<'ok' | 'error'> {
    loading.value = true;
    error.value = null;
    try {
      const res = await userApi.getAvatar(user.value.id);
      if (isErrorMessageResponse(res)) {
        if (res.errorCode === 'NOT_FOUND') {
          user.value && (user.value = { ...user.value, avatar: null });
          avatar.value = null;
          return 'ok';
        } else {
          return 'error';
        }
      }
      user.value && (user.value = { ...user.value, avatar: res });
      avatar.value = res;
      return 'ok';
    } catch (error) {
      error.value = 'Ошибка обновления';
      return 'error';
    } finally {
      loading.value = false;
    }
  }

  async function updateCurrentUser(data: UserUpdatedData): Promise<'ok' | 'error'> {
    const dataToSend = {};
    Object.keys(data).forEach((key) => {
      if (data[key] !== this.user[key] && typeof data[key] !== 'undefined') {
        dataToSend[key] = data[key];
      }
    });
    try {
      const response = await userApi.update(this.user.id, dataToSend);
      if (isErrorMessageResponse(response)) {
        error.value = response.errorCode;
        return 'error';
      }
      if (isUser(response)) {
        user.value = { ...user.value, ...response, assignedMinutes: response.remainingMinutes, avatar: avatar.value };
        const companyStore = useCompanyStore();
        const idx = companyStore.users.findIndex((employee) => employee.id === user.value.id);
        if (idx !== -1) {
          companyStore.users[idx] = {
            ...companyStore.users[idx],
            ...response,
            assignedMinutes: response.remainingMinutes,
            avatarUrl: avatar.value,
          };
        }
        return 'ok';
      }
    } catch (error) {
      this.error = 'Ошибка обновления';
      return 'error';
    }
  }

  async function logout() {
    try {
      await signOut();
    } catch (error) {
      this.error = error?.message || 'error';
    }
  }

  const isManager = computed(() => {
    const rolesCodes = user.value.roles.map((role) => role?.code);
    return rolesCodes.length ? rolesCodes.some((code) => code?.toLowerCase()?.includes('manager')) : false;
  });

  function updateBalance(balance: number) {
    user.value.remainingMinutes = balance;
  }

  return {
    updateBalance,
    $reset,
    avatar,
    getAvatar,
    updateAvatar,
    isManager,
    updateCurrentUser,
    logout,
    user,
    hydrate,
    dehydrate,
    loading,
    error,
    updateUser,
  };
});
